<template>
  <div class="mt-1 d-flex flex-row">
    <component
      :is="filterItem.component"
      v-for="filterItem in computedFilters"
      :key="filterItem.fieldName"
      v-model="filters[filterItem.modelValue]"
      :options="filterItem.options"
      :label="filterItem.displayableName"
      class="m-1"
    />
    <div
      class="flex-row d-inline-flex mb-3 align-items-end"
    >
      <button
        class="btn btn-primary me-1"
        style="height: fit-content;"
        @click="filterClickHandler"
      >
        Filter
      </button>
      <button
        class="btn btn-warning ms-1"
        style="height: fit-content;"
        @click="resetFilterClickHandler"
      >
        Reset
      </button>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import SelectInput from '@/components/common/SelectInput';
import TextInput from '@/components/common/TextInput';

export const filterTypeToComponentName = {
  SELECT: 'SelectInput',
  TEXT: 'TextInput'
};

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SelectInput,
    // eslint-disable-next-line vue/no-unused-components
    TextInput,
  },
  props: ['filtersDefs'],
  emits: [ 'filter', 'filterReset' ],
  data() {
    return {
      filters: {}
    };
  },
  computed: {
    computedFilters() {
      return this.buildFilters(this.filtersDefs);
    },
  },
  methods: {
    buildFilters(filtersList) {
      return _.map(filtersList, (filterDef) => {
        const defaultOptions = [{ key: '', value: '' }];
        let options;
        if (filterDef.options) {
          options = [ ...defaultOptions, ...filterDef.options ];
        }
        return {
          ...filterDef,
          component: filterTypeToComponentName[filterDef.type],
          modelValue: filterDef.fieldName,
          options
        };
      });
    },
    filterClickHandler() {
      const queryString = _.reduce(this.filters, (acc, value, key) => {
        if (value) {
          acc += `&${key}=${encodeURIComponent(value)}`;
        }
        return acc;
      }, '');
      this.$emit('filter', queryString);
    },
    resetFilterClickHandler() {
      this.filters = {};
      this.$emit('filterReset');
    }
  }
};
</script>
