<template>
  <div
    id="audit-modal"
    ref="audit-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog audit-log__modal">
      <div class="modal-content">
        <div class="modal-header">
          <h5>
            Audit Log: {{ log.id }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <TextInput
            v-model="log.entity"
            label="Entity:"
            readonly
          />
          <div
            v-if="log.entityId != -1"
          >
            <TextInput
              v-model="log.entityId"
              label="Entity Id:"
              readonly
            />
          </div>
          <div class="mt-3">
            <label class="form-label form-label">
              Info:
            </label>
            <AuditLogDifferenceTable
              :currentState="currentState"
              :previousState="previousState"
            />
          </div>
          <TextInput
            v-model="log.commitDate"
            label="Changed date:"
            readonly
          />
          <TextInput
            v-model="log.username"
            label="Changed by:"
            readonly
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Modal } from 'bootstrap';
import TextInput from '../common/TextInput';
import AuditLogDifferenceTable from './AuditLogDifferenceTable';

export default {
  name: 'AuditModal',
  components: {
    TextInput,
    AuditLogDifferenceTable
  },
  data() {
    return {
      log: {},
      previousLog: {},
      currentState: {},
      previousState: {}
    };
  },
  async mounted() {
    this.editModal = new Modal(this.$refs['audit-modal']);
  },
  methods: {
    ...mapActions({
      fetchLog: 'auditLog/fetchLog',
      fetchPreviousLog: 'auditLog/fetchPreviousLog'
    }),
    async initModal(id) {
      try {
        this.log = await this.fetchLog(id);
        this.currentState = JSON.parse(this.log.state);
        try {
          this.previousLog = await this.fetchPreviousLog(id);
          this.previousState = JSON.parse(this.previousLog.state);
        } catch (e) {
          this.previousLog = {};
          this.previousState = {};
        }
        this.editModal.show();
      } catch {
        this.$toast.show('Failed to fetch the log.', { type: 'error' });
      }
    },
  }
};
</script>
<style>
.audit-log__modal {
  width: 600px;
  max-width: 600px;
}
</style>
