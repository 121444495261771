<template>
  <div
    class="mb-1 difference-table__container"
  >
    <table class="table table-bordered">
      <thead>
        <tr>
          <td
            scope="col"
            class="difference-table__col"
          >
            Key
          </td>
          <td
            scope="col"
            class="difference-table__col"
          >
            Previous Value
          </td>
          <td
            scope="col"
            class="difference-table__col"
          >
            Current Value
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in tableRows"
          :key="row.key"
          :class="{ 'changed-row': row.previous !== row.current }"
        >
          <td>{{ row.key }}</td>
          <td>{{ row.previous }}</td>
          <td>{{ row.current }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  props: [ 'currentState', 'previousState' ],
  computed: {
    keysToCompare() {
      const current = _.keys(this.currentState);
      const previous = _.keys(this.previousState);
      return _.uniq([ ...current, ...previous ]);
    },
    tableRows() {
      const result = [];
      _.forEach(this.keysToCompare, (key) => {
        result.push({
          key,
          previous: JSON.stringify(this.previousState[key]),
          current: JSON.stringify(this.currentState[key])
        });
      });
      return result;
    }
  }
};
</script>
<style>
.difference-table__container {
  overflow-x: auto;
}

.difference-table__col {
  min-width: 150px;
}

.changed-row {
  background-color: rgba(252, 213, 35, 0.3)
}
</style>
