<template>
  <div
    class="container mt-5 audit-log__container"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h5 class="mt-1 mb-0">
              Audit log
            </h5>
          </div>
          <div
            class="card-body d-flex flex-column"
            style="height: 42em"
          >
            <div class="text-muted mb-2">
              Total count: {{ totalElements }}
            </div>
            <FilterBar
              :filtersDefs="filtersDefs"
              @filter="onFilterHandler"
              @filterReset="onFilterResetHandler"
            />
            <ag-grid-vue
              :columnDefs="columnsAuditLogs"
              :defaultColDef="defaultColDef"
              :rowData="rowsAuditLogs"
              class="ag-theme-alpine flex-grow-1 w-100"
              @row-clicked="tableRowClickHandler"
            />
            <AuditLogModal
              ref="auditModal"
            />
          </div>
          <div class="card-footer">
            <PaginationBar
              :totalPages="totalPages"
              :initialSelectedSize="selectedSize"
              @paginationChanged="paginationChangedHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { AgGridVue } from 'ag-grid-vue3';
import AuditLogModal from '@/components/AuditLog/AuditLogModal.vue';
import FilterBar from '@/components/common/FilterBar';
import PaginationBar from '@/components/common/PaginationBar';

export default {
  name: 'AuditLogs',
  components: {
    FilterBar,
    AgGridVue,
    AuditLogModal,
    PaginationBar
  },
  data() {
    return {
      selectedSize: 20,
      currentPage: 1,
      filtersDefs: [],
      queryString: '',
      response: {},
    };
  },
  computed: {
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
    },
    columnsAuditLogs() {
      return [
        {
          headerName: 'ID',
          field: 'entityId',
          width: 80
        },
        {
          headerName: 'Entity',
          field: 'entity'
        },
        {
          headerName: 'Brand',
          field: 'brand',
          width: 150
        },
        {
          headerName: 'Modification',
          field: 'modification',
          width: 100
        },
        {
          headerName: 'Changed properties',
          field: 'changedProperties',
          width: 300
        },
        {
          headerName: 'Username',
          field: 'username',
        },
        {
          headerName: 'Date',
          field: 'commitDate',
          width: 200
        },
        {
          headerName: 'Version',
          field: 'version',
          width: 100
        }
      ];
    },
    rowsAuditLogs() {
      return this.response?.content || [];
    },
    totalPages() {
      return this.response?.totalPages || 1;
    },
    totalElements() {
      return this.response.totalElements || 0;
    }
  },
  async mounted() {
    this.filtersDefs = await this.fetchFilters();
    await this.loadAuditLogs();
  },
  methods: {
    ...mapActions({
      fetchAuditLogs: 'auditLog/fetchAllLogs',
      fetchFilters: 'auditLog/fetchFilters'
    }),
    async loadAuditLogs() {
      try {
        this.response = await this.fetchAuditLogs({
          currentPage: this.currentPage,
          selectedSize: this.selectedSize,
          queryString: this.queryString,
        });
      } catch {
        this.$toast.show('Failed to load audit logs.', { type: 'error' });
      }
    },
    tableRowClickHandler(rowClickedEvent) {
      const logId = rowClickedEvent.data['id'];
      this.initModal(logId);
    },
    initModal(logId){
      this.$refs.auditModal.initModal(logId);
    },

    async paginate(page) {
      this.currentPage = page;
      this.$refs[`item-${page}`].scrollIntoView();
      await this.loadAuditLogs();
    },
    async onFilterResetHandler() {
      this.queryString = '';
      await this.loadAuditLogs();
    },
    async onFilterHandler(e) {
      this.queryString = e;
      await this.loadAuditLogs();
    },
    async paginationChangedHandler(e) {
      this.selectedSize = e.selectedSize;
      this.currentPage = e.currentPage;
      await this.loadAuditLogs();
    }
  }
};
</script>
<style>
.pagination__wrapper {
  display: flex;
  flex-direction: row;
}
.pagination__scrollbar {
  max-width: 500px;
  overflow: hidden;
  overflow-x: auto;
}

.pagination__scrollbar::-webkit-scrollbar {
  height: 10px;
}

.pagination__scrollbar::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.pagination__scrollbar::-webkit-scrollbar-thumb {
  background: #e1e1e1;
}

.audit-log__container {
  width: 1400px;
  max-width: 1400px;
}
</style>
