<template>
  <div class="pagination-bar__container">
    <div>
      <SelectInput
        v-model="selectedSize"
        :options="paginationSizeOptions"
        selectClass="form-select-sm"
        style="width: 70px;"
        @change="onPaginationSizeChanged"
      />
    </div>
    <div class="pagination__wrapper">
      <ul class="pagination pagination-sm mb-0">
        <li
          :class="{ 'page-item': true, 'disabled': currentPage === 1 }"
        >
          <button
            aria-disabled="true"
            class="page-link"
            tabindex="-1"
            @click="onPaginationPageChanged(currentPage - 1)"
          >
            Previous
          </button>
        </li>
      </ul>
      <ul class="pagination pagination-sm mb-0 pagination__scrollbar">
        <li
          v-for="pageNumber in totalPages"
          :ref="`item-${pageNumber}`"
          :key="pageNumber"
          :class="{ 'page-item': true, 'active': pageNumber === currentPage }"
        >
          <button
            class="page-link"
            @click="onPaginationPageChanged(pageNumber)"
          >
            {{ pageNumber }}
          </button>
        </li>
      </ul>
      <ul class="pagination pagination-sm mb-0">
        <li
          :class="{ 'page-item': true, 'disabled': currentPage === totalPages }"
        >
          <button
            class="page-link"
            @click="onPaginationPageChanged(currentPage + 1)"
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import SelectInput from '@/components/common/SelectInput';

export default {
  components: {
    SelectInput
  },
  props: [ 'totalPages', 'initialSelectedSize' ],
  emits: ['paginationChanged'],
  data() {
    const sizing = [ 10, 20, 50, 100 ];
    return {
      sizing,
      selectedSize: this.initialSelectedSize || sizing[0],
      currentPage: 1,
    };
  },
  computed: {
    paginationSizeOptions() {
      return _.map(this.sizing, (s) => ({ key: s, value: s }));
    }
  },
  methods: {
    onPaginationSizeChanged() {
      this.currentPage = 1;
      this.$emit('paginationChanged', this.buildPaginationEventData());
    },
    onPaginationPageChanged(pageNumber) {
      this.currentPage = pageNumber;
      this.$emit('paginationChanged', this.buildPaginationEventData());
    },
    buildPaginationEventData() {
      return {
        selectedSize: this.selectedSize,
        currentPage: this.currentPage
      };
    }
  }
};
</script>
<style>
.pagination-bar__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pagination__wrapper {
  display: flex;
  flex-direction: row;
}
.pagination__scrollbar {
  max-width: 500px;
  overflow: hidden;
  overflow-x: auto;
}

.pagination__scrollbar::-webkit-scrollbar {
  height: 10px;
}

.pagination__scrollbar::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.pagination__scrollbar::-webkit-scrollbar-thumb {
  background: #e1e1e1;
}
</style>
